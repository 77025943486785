import { useStaticQuery, graphql, Link } from "gatsby"
import React, { useEffect, useState } from 'react'

export default function Books() {

  const data = useStaticQuery(graphql`
    {
      allStrapiBook {
        nodes {
          id
          Title
          Publisher
          Year
        }
      }
    }
  `)

  return (
    <div>
      <p>Last Books</p>
      <ul>
        {data.allStrapiBook.nodes.map(book=><li>{book.Title}</li>)}
      </ul>
    </div>
  )
}
